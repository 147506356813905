<template>
  <div v-if="service.consultations" class="addServiceOnExpert">
    <div class="row">
      <div class="col-lg-6 expertAddPage__input">
        <span
          style="left: 10px"
          :class="{ 'baseInput__area-focus': service.type }"
          class="baseInput__label"
        >
          {{ $t("serviceType") }}*
        </span>
        <vue-multiselect
          class="accountOverviewMultiselect"
          :options="serviceType"
          @select="updTypeValue"
          v-model="service.type"
          :show-labels="false"
          placeholder=""
          openDirection="bottom"
          label="name"
          track-by="name"
        />
      </div>
    </div>
    <div class="addServiceOnExpert__value-wrapper">
      <div
        class="row addServiceOnExpert__value"
        v-for="(product, index) in service.consultations"
        :key="product.id"
      >
        <div class="col-xl-5 col-xxl-6 text-end position-relative">
          <div class="d-flex justify-content-end align-items-center">
            <div
              v-if="language[index]"
              class="addServiceOnExpert__language"
              :class="{
                'addServiceOnExpert__language-active': language[index].english,
              }"
              @click="
                language[index].english = true;
                language[index].french = false;
              "
            >
              ENG
            </div>
            <div
              v-if="language[index]"
              class="addServiceOnExpert__language"
              :class="{
                'addServiceOnExpert__language-active': language[index].french,
              }"
              @click="
                language[index].french = true;
                language[index].english = false;
              "
            >
              FR
            </div>
            <close-button-icon
              class="addServiceOnExpert__deleteIcon"
              @click="deleteProduct(index)"
            />
          </div>
          <div v-if="product.translations && language[index]">
            <base-input
              v-if="language[index].english && v$.service.consultations[index]"
              class="w-100"
              type="text"
              v-model="product.translations[0].name"
              :error="
                v$.service.consultations[index].translations[0].name.$error
              "
              :error-message="
                v$.service.consultations[index].translations[0].name.$errors
              "
            >
              {{ $t("enProductName") }}
            </base-input>
            <base-input
              v-if="language[index].french && v$.service.consultations[index]"
              class="w-100"
              type="text"
              v-model="product.translations[1].name"
              :error="
                v$.service.consultations[index].translations[1].name.$error
              "
              :error-message="
                v$.service.consultations[index].translations[1].name.$errors
              "
            >
              {{ $t("frProductName") }}
            </base-input>
            <small
              class="error-message"
              v-if="
                !!v$.service.consultations[index] &&
                ((v$.service.consultations[index].translations[0].name.$error &&
                  !language[index].english) ||
                  (v$.service.consultations[index].translations[1].name
                    .$error &&
                    !language[index].french))
              "
            >
              {{ $t("PleaseAddTranslation") }}
            </small>
          </div>
        </div>
        <div class="col-xl-2 col-xxl-2">
          <base-input
            v-if="v$.service.consultations[index]"
            class="w-100"
            type="number"
            v-model="product.price"
            :error="v$.service.consultations[index].price.$error"
            :error-message="v$.service.consultations[index].price.$errors"
          >
            {{ $t("price") }}, €
          </base-input>
        </div>
        <div class="col-xl-3 col-xxl-2">
          <base-input
            v-if="v$.service.consultations[index]"
            lass="w-100"
            type="number"
            v-model="product.expert_income"
            :error="v$.service.consultations[index].expert_income.$error"
            :error-message="
              v$.service.consultations[index].expert_income.$errors
            "
          >
            {{ $t("forAnExpert") }}, €
          </base-input>
        </div>
        <div class="col-xl-2 col-xxl-2">
          <base-input
            v-if="v$.service.consultations[index]"
            class="w-100"
            type="number"
            v-model="product.duration"
            :error="v$.service.consultations[index].duration.$error"
            :error-message="v$.service.consultations[index].duration.$errors"
          >
            {{ $t("durationMin") }}
          </base-input>
        </div>
      </div>
    </div>
    <div v-if="service.type" class="addServiceOnExpert__footer">
      <base-button
        v-if="service.type.length > 0"
        margin-left="0"
        @click="addProduct()"
        >{{ $t("addProduct") }}
      </base-button>
      <base-button
        @click="removeService(index)"
        class="addServiceOnExpert__remove"
        :class="{
          'addServiceOnExpert__remove-margin': service.type.length > 0,
        }"
        >{{ $t("removeService") }}
      </base-button>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import BaseInput from "@/components/UI/inputs/BaseInput";
import BaseButton from "@/components/UI/buttons/BaseButton";
import CloseButtonIcon from "@/components/UI/icons/CloseButtonIcon";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  props: {
    index: Number,
    serviceInformation: Object,
    removeService: Function,
    modelValue: Object,
  },
  components: { CloseButtonIcon, BaseButton, BaseInput, VueMultiselect },
  name: "AddServiceOnExpert",
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      serviceType: [],
      typeValue: {},
      language: [{ english: true, french: false }],
      service: {
        id: "",
        type: [],
        consultations: [
          {
            id: null,
            service_id: null,
            translations: [
              { name: "", id: 1 },
              { name: "", id: 2 },
            ],
            price: "",
            expert_income: "",
            duration: "",
          },
        ],
      },
      validateArray: {
        consultations: [],
      },
    };
  },
  validations() {
    return {
      service: this.validateArray,
    };
  },
  mounted() {
    this.getServices();
    this.service = this.serviceInformation;
    if (this.service.consultations.length !== 0) {
      let validateObj = [
        {
          translations: [
            { name: { required }, id: 1 },
            { name: { required }, id: 2 },
          ],
          price: { required },
          expert_income: { required },
          duration: { required },
        },
      ];
      if (this.service.consultations.length > 1) {
        for (let i = 0; i < this.service.consultations.length - 1; i++) {
          validateObj.push({
            translations: [
              { name: { required }, id: 1 },
              { name: { required }, id: 2 },
            ],
            price: { required },
            expert_income: { required },
            duration: { required },
          });
        }
      }
      this.validateArray = {
        consultations: validateObj,
      };
    }
  },
  methods: {
    getServices() {
      this.$http.get("services", {}).then(({ data }) => {
        this.serviceType = data.body;
        this.service.type = this.serviceType.filter(
          (service) => service.id === this.service.id
        );
      });
    },
    updTypeValue(event) {
      this.service.id = event.id;
      this.service.name = event.name;
    },
    addProduct() {
      this.service.consultations.push({
        id: null,
        service_id: null,
        translations: [
          {
            name: "",
            id: null,
            language_id: 1,
            consultation_id: null,
          },
          {
            name: "",
            id: null,
            language_id: 2,
            consultation_id: null,
          },
        ],
        price: "",
        expert_income: "",
        duration: "",
      });
      let validateObj = [];
      for (let i = 0; i < this.service.consultations.length; i++) {
        validateObj.push({
          translations: [
            { name: { required }, id: 1 },
            { name: { required }, id: 2 },
          ],
          price: { required },
          expert_income: { required },
          duration: { required },
        });
      }
      this.validateArray = {
        consultations: validateObj,
      };
    },
    deleteProduct(product) {
      this.v$.$reset();
      this.service.consultations.splice(product, 1);
      const validateArray = this.validateArray.consultations.filter(
        (item, index) => index !== product
      );
      this.validateArray = {
        consultations: validateArray,
      };
    },
  },
  watch: {
    serviceInformation: {
      deep: true,
      handler(newCount) {
        this.service.consultations.forEach(() => {
          this.language.push({ english: true, french: false });
        });
        this.$emit("update:modelValue", newCount);
      },
    },
  },
};
</script>

<style></style>
