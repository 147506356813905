<template>
  <main-layout>
    <div class="d-flex align-items-center">
      <base-button @click="$router.back()" :back="true">
        <horizontal-arrow />
      </base-button>
      <h1 class="m-0 ms-3">{{ $t("editExpertInformation") }}</h1>
    </div>
    <div class="expertAddPage__steps-wrapper justify-content-start">
      <div
        class="expertAddPage__steps expertEditPage__steps cursor-pointer"
        :class="{ 'expertAddPage__steps-active': steps[0] }"
        @click="changeStep(0)"
      >
        {{ $t("Information") }}
      </div>
      <div
        class="expertAddPage__steps expertEditPage__steps cursor-pointer"
        :class="{ 'expertAddPage__steps-active': steps[1] }"
        @click="changeStep(1)"
      >
        {{ $t("Biography") }}
      </div>
      <div
        class="expertAddPage__steps expertEditPage__steps cursor-pointer"
        :class="{ 'expertAddPage__steps-active': steps[2] }"
        @click="changeStep(2)"
      >
        {{ $t("sidebarServicesBtn") }}
      </div>
      <div
        class="expertAddPage__steps expertEditPage__steps cursor-pointer"
        :class="{ 'expertAddPage__steps-active': steps[3] }"
        @click="changeStep(3)"
      >
        {{ $t("Avatar") }}
      </div>
    </div>
    <div v-show="steps[0]" class="employeesSinglePage__userInfo w-50">
      <avatar
        v-if="isLoaded"
        user-role="experts"
        :user-code="imageCode"
        :image-name="oldImage"
        size="100"
        height="100px"
        width="100px"
      />
      <div class="ms-3">
        <div class="employeesSinglePage__name">
          {{ information.name + " " + information.surname }}
        </div>
        <div class="employeesSinglePage__code">{{ information.code }}</div>
      </div>
    </div>
    <div class="expertAddPage">
      <div v-show="steps[0]" class="row">
        <h5 class="employeesSinglePage__title">
          {{ $t("expertInformation") }}
        </h5>
        <div class="col-12 col-lg-6 pr-25">
          <div class="expertAddPage__input">
            <base-input
              class="w-100"
              v-model="information.name"
              type="text"
              :error="v$.information.name.$error"
              :error-message="v$.information.name.$errors"
            >
              {{ $t("accountOverviewPageName") }}*
            </base-input>
          </div>
          <div class="expertAddPage__input">
            <base-input
              class="w-100"
              v-model="information.surname"
              type="text"
              :error="v$.information.surname.$error"
              :error-message="v$.information.surname.$errors"
            >
              {{ $t("accountOverviewPageSurname") }}*
            </base-input>
          </div>
          <div class="expertAddPage__input expertAddPage-edit__input-language">
            <base-input
              class="w-100"
              v-model="information.code"
              :maxlength="15"
              type="text"
              :error="v$.information.code.$error"
              :error-message="v$.information.code.$errors"
            >
              {{ $t("expertReference") }}*
            </base-input>
          </div>
          <base-input
            class="w-100 expertAddPage__input"
            v-model="information.country"
            >Country
          </base-input>
          <div class="row">
            <div class="col-lg-6">
              <base-input
                class="w-100 expertAddPage__input"
                v-model="information.city"
                >City
              </base-input>
            </div>
            <div class="col-lg-6">
              <base-input
                class="w-100 expertAddPage__input"
                v-model="information.postal_code"
                >Postal code
              </base-input>
            </div>
          </div>
          <div class="expertAddPage__input">
            <base-input class="w-100" v-model="information.address" type="text"
              >{{ $t("accountOverviewPageAddress") }}
            </base-input>
          </div>
        </div>
        <div class="col-12 col-lg-6 pl-25">
          <div class="d-flex justify-content-between">
            <div class="expertAddPage__input accountPage__input-small">
              <span
                :class="{
                  'baseInput__area-focus expertAddPage__input-label':
                    information.gender,
                }"
                class="baseInput__label"
              >
                {{ $t("accountOverviewPageGender") }}
              </span>
              <vue-multiselect
                class="accountOverviewMultiselect"
                :options="genderOptions"
                v-model="information.gender"
                placeholder=""
                :show-labels="false"
                openDirection="bottom"
                label="name"
                track-by="name"
              />
            </div>
            <div class="expertAddPage__input expertAddPage__input-small">
              <span
                class="baseInput__label"
                :class="{ 'baseInput__area-focus': information.birth }"
              >
                {{ $t("accountOverviewPageBirth") }}</span
              >
              <datepicker
                inputClassName="registrationPage__datePicker"
                class=""
                v-model="information.birth"
                format="dd/MM/yyyy"
                :flow="flow"
                hideInputIcon
                autoApply
              />
            </div>
          </div>
          <div class="expertAddPage__input">
            <base-input
              class="w-100"
              v-model="information.email"
              type="email"
              :error="v$.information.email.$error"
              :error-message="v$.information.email.$errors"
            >
              {{ $t("accountOverviewPageEmail") }}*
            </base-input>
          </div>
          <div class="expertAddPage__input">
            <span
              :class="{
                'baseInput__area-focus expertAddPage__input-label':
                  information.platformLanguage,
              }"
              class="baseInput__label"
            >
              {{ $t("platformLanguage") }}*
            </span>
            <vue-multiselect
              class="accountOverviewMultiselect"
              :options="platformLanguageOptions"
              v-model="information.platformLanguage"
              placeholder=""
              :show-labels="false"
              openDirection="bottom"
              label="name"
              track-by="name"
            />
          </div>
          <div class="expertAddPage__input">
            <span
              :class="{
                'baseInput__area-focus expertAddPage__input-label':
                  information.languages.length > 0,
              }"
              class="baseInput__label"
            >
              {{ $t("expertsLanguages") }}*
            </span>
            <vue-multiselect
              class="accountOverviewMultiselect"
              :class="{ baseInput__error: v$.information.languages.$error }"
              :multiple="true"
              :taggable="true"
              :options="languageOptions"
              v-model="information.languages"
              placeholder=""
              :show-labels="false"
              openDirection="bottom"
              label="name"
              track-by="name"
            />
            <small v-if="v$.information.languages.$error">{{
              $t("fieldRequireError")
            }}</small>
          </div>
          <div class="expertAddPage__input">
            <base-input
              class="w-100"
              v-model="information.office_number"
              type="phone"
            >
              {{ $t("accountOverviewPageOffice") }}
            </base-input>
          </div>
          <div class="expertAddPage__input">
            <base-input
              class="w-100"
              v-model="information.mobile_number"
              type="phone"
              >Mobile number
            </base-input>
          </div>
          <div class="expertAddPage__input">
            <base-input
              class="w-100"
              v-model="information.phone_number"
              type="phone"
              >{{ $t("accountOverviewPagePhone") }}
            </base-input>
          </div>
        </div>
      </div>
      <div v-show="steps[1]">
        <div class="d-flex justify-content-center">
          <div
            class="expertAddPage__language"
            :class="{ 'expertAddPage__language-active': !language.english }"
            @click="languageChange('english')"
          >
            {{ $t("French") }}
          </div>
          <div
            class="expertAddPage__language"
            :class="{ 'expertAddPage__language-active': !language.french }"
            @click="languageChange('french')"
          >
            {{ $t("English") }}
          </div>
        </div>
        <h5 class="employeesSinglePage__title mt-4">Expert biography</h5>
        <div class="d-flex justify-content-between">
          <base-input
            v-show="language.english"
            v-model="information.position.english"
            class="servicesAddPage__input mt-0"
          >
            {{ $t("accountOverviewPagePosition") }}
          </base-input>
          <base-input
            v-show="language.french"
            v-model="information.position.french"
            class="servicesAddPage__input mt-0"
          >
            {{ $t("accountOverviewPagePosition") }}
          </base-input>
        </div>
        <div class="expertAddPage__titleContainer">
          <div class="expertAddPage__title">{{ $t("shortDescription") }}</div>
        </div>
        <div class="expertAddPage__shortDescription">
          <div v-show="language.english">
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="information.shortBiography.english"
            ></ckeditor>
          </div>
          <div v-show="language.french">
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="information.shortBiography.french"
            ></ckeditor>
          </div>
        </div>
        <div class="expertAddPage__titleContainer">
          <div class="expertAddPage__title">{{ $t("fullDescription") }}</div>
        </div>
        <div class="expertAddPage__fullDescription">
          <div v-show="language.english">
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="information.fullBiography.english"
            ></ckeditor>
          </div>
          <div v-show="language.french">
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="information.fullBiography.french"
            ></ckeditor>
          </div>
        </div>
      </div>
      <div v-show="steps[2]">
        <add-service-on-expert
          v-for="service in services"
          :key="service.id"
          :index="service.id"
          :service-information="service"
          :remove-service="removeService"
          v-model="servicesValue[service.id]"
        />
        <div class="expertAddPage__addServices" @click="addService()">
          {{ $t("addServiceOnExpert") }}
        </div>
      </div>
      <div v-show="steps[3]" class="expertAddPage__stepThree">
        <div class="expertAddPage__oldImage">
          <avatar
            v-if="oldImage"
            :drag-and-drop-image="true"
            :user-code="imageCode"
            :image-name="oldImage"
            user-role="experts"
            size="500"
            width="100%"
            height="100%"
          />
        </div>
        <drag-and-drop
          ref="thumbnail"
          :avatar="true"
          v-model="thumbnail"
          :have-old-image="oldImage"
        />
      </div>
      <div
        class="expertAddPage__footer"
        :class="{
          'justify-content-between': steps[3] && (oldImage || thumbnail),
        }"
      >
        <base-button
          v-if="steps[3] && (oldImage || thumbnail)"
          class="popup__remove-logo-btn"
          @click="removeImage()"
        >
          {{ $t("RemoveAvatar") }}
        </base-button>
        <div class="expertAddPage__buttons">
          <div
            v-show="haveChangesComputed"
            @click="getExpertInformation()"
            class="accountPage__cancel"
          >
            {{ $t("discardChanges") }}
          </div>
          <base-button
            :is-loaded="imageIsLoad"
            :book="true"
            @click="editExpert()"
            >{{ $t("accountOverviewPageSave") }}
          </base-button>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import AddServiceOnExpert from "@/components/elements/AddServiceOnExpert";
import DragAndDrop from "@/components/elements/DragAndDrop";
import BaseButton from "@/components/UI/buttons/BaseButton";
import BaseInput from "@/components/UI/inputs/BaseInput";
import VueMultiselect from "vue-multiselect";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Avatar from "@/components/UI/images/Avatar";
import { format } from "date-fns";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { email, minLength, required } from "@vuelidate/validators";
import { ref } from "vue";
import Datepicker from "@vuepic/vue-datepicker";

export default {
  name: "ExpertEditPage",
  components: {
    HorizontalArrow,
    Avatar,
    AddServiceOnExpert,
    DragAndDrop,
    BaseButton,
    BaseInput,
    MainLayout,
    VueMultiselect,
    Datepicker,
  },
  setup() {
    const toast = useToast();
    const v$ = useVuelidate();
    const flow = ref(["year", "month", "calendar"]);

    return {
      flow,
      toast,
      v$,
    };
  },
  data() {
    return {
      goToExperts: true,
      isLoaded: false,
      haveChanges: true,
      informationBackups: {},
      services: [],
      servicesValue: [],
      thumbnail: null,
      imageCode: null,
      oldImage: null,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "blockQuote",
            "undo",
            "redo",
          ],
        },
      },
      steps: [true, false, false, false],
      step: 0,
      genderOptions: [],
      languageOptions: [],
      platformLanguageOptions: [],
      language: {
        english: false,
        french: true,
      },
      information: {
        id: "",
        name: "",
        surname: "",
        code: "",
        password: "",
        country: "",
        city: "",
        postal_code: "",
        address: "",
        mobile_number: "",
        gender: "",
        birth: null,
        email: "",
        languages: "",
        platformLanguage: "",
        position: {
          english: "",
          french: "",
        },
        office_number: "",
        phone_number: "",
        shortBiography: {
          english: "",
          french: "",
        },
        fullBiography: {
          english: "",
          french: "",
        },
      },
    };
  },
  validations() {
    return {
      information: {
        name: { required },
        surname: { required },
        email: { required, email },
        code: { required, minLength: minLength(13) },
        languages: { required },
      },
    };
  },
  mounted() {
    this.getOptions();
    this.getExpertInformation();
    this.$store.commit("SET_LOADED_STATE", false);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.haveChanges) {
      let changesPopup = {
        show: true,
        to: to.path,
        save: null,
      };
      this.$store.commit("SET_STATE_POPUP", changesPopup);
    } else {
      return next();
    }
  },
  methods: {
    removeImage() {
      this.thumbnail = null;
      this.$refs.thumbnail.remove();
      if (this.oldImage) {
        this.oldImage = null;
        this.goToExperts = false;
        this.editExpert();
      }
    },
    getOptions() {
      this.$http.get("/platform-languages", {}).then(({ data }) => {
        this.platformLanguageOptions = data.body;
      });
      this.$http.get("/genders", {}).then(({ data }) => {
        this.genderOptions = data.body;
      });
      this.$http.get("/languages", {}).then(({ data }) => {
        this.languageOptions = data.body;
      });
    },
    languageChange(language) {
      for (let i in this.language) {
        this.language[i] = i !== language;
      }
    },
    getExpertInformation() {
      this.$http
        .get("experts/" + this.$route.params.expert)
        .then(({ data }) => {
          this.information.id = data.body.user_id;
          let info = {
            is_blocked: data.body.user.is_blocked,
            user_id: data.body.user_id,
            profile_id: data.body.user.profile_id,
            role_id: 2,
            last_name: data.body.last_name,
            first_name: data.body.first_name,
            previewPath: "/expert/" + data.body.code,
            email: data.body.user.email,
          };
          this.$store.commit("SET_EDIT_USER_INFORMATION", info);
          this.information.name = data.body.first_name;
          this.information.platformLanguage =
            this.platformLanguageOptions.filter(
              (element) => element.id === data.body.user.language_id
            )[0];
          this.information.surname = data.body.last_name;
          this.information.code = data.body.code;
          this.imageCode = JSON.parse(JSON.stringify(data.body.code));
          this.information.position.english =
            data.body.translations[0].position;
          this.information.position.french = data.body.translations[1].position;
          this.information.address = data.body.address;
          this.information.city = data.body.city;
          this.information.country = data.body.country;
          this.information.postal_code = data.body.postal_code;
          this.information.mobile_number = data.body.mobile_number;
          this.information.gender = this.genderOptions.filter(
            (element) => element.id === data.body.gender_id
          )[0];
          this.information.birth = format(
            new Date(data.body.birthdate),
            "yyyy-MM-dd"
          );
          this.information.email = data.body.user.email;
          this.information.languages = data.body.languages;
          this.information.office_number = data.body.office_number;
          this.information.phone_number = data.body.phone_number;
          this.information.shortBiography.english =
            data.body.translations[0].short_biography !== null
              ? data.body.translations[0].short_biography
              : "";
          this.information.shortBiography.french =
            data.body.translations[1].short_biography !== null
              ? data.body.translations[1].short_biography
              : "";
          this.information.fullBiography.english =
            data.body.translations[0].biography !== null
              ? data.body.translations[0].biography
              : "";
          this.information.fullBiography.french =
            data.body.translations[1].biography !== null
              ? data.body.translations[1].biography
              : "";
          this.oldImage = data.body.image;
          this.services = data.body.services;
          this.informationBackups = JSON.parse(
            JSON.stringify(this.information)
          );
          this.isLoaded = true;
        });
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.languageOptions.push(tag);
      this.information.languages.push(tag);
    },
    changeStep(step) {
      for (let i in this.steps) {
        this.steps[i] = false;
      }
      this.steps[step] = true;
    },
    addService() {
      this.services.push({ id: null, type: "", consultations: [] });
    },
    removeService(value) {
      this.services = this.services.filter((element) => element.id !== value);
    },
    editExpert() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.toast.error(this.$t("checkInputs"));
        return;
      }
      if (!this.imageIsLoad) {
        this.$store.commit("SET_LOADED_STATE", true);
        let languages = [];
        let services = [];
        let image = this.thumbnail !== null ? this.thumbnail : this.oldImage;
        this.information.languages.forEach((item) => {
          languages.push(item.id);
        });
        let serviceValue = [];
        this.services.forEach((item) => {
          this.servicesValue.forEach((itemValue) => {
            if (item.id === itemValue.id) {
              serviceValue.push(itemValue);
            }
          });
        });
        serviceValue.forEach((service) => {
          service.consultations.forEach((item) => {
            services.push({
              id: item.id ? Number(item.id) : null,
              service_id: Number(service.id),
              translations: item.translations,
              price: Number(item.price),
              expert_income: Number(item.expert_income),
              duration: Number(item.duration),
            });
          });
        });
        this.$http
          .patch("experts/" + this.information.id, {
            gender_id: this.information.gender
              ? this.information.gender.id
              : null,
            code: this.information.code,
            first_name: this.information.name,
            last_name: this.information.surname,
            birthdate: new Date(this.information.birth).toISOString(),
            address: this.information.address,
            country: this.information.country,
            city: this.information.city,
            postal_code: this.information.postal_code,
            mobile_number: this.information.mobile_number,
            phone_number: this.information.phone_number,
            office_number: this.information.office_number,
            image: image,
            is_activated: true,
            languages: languages,
            profile: {
              language_id: this.information.platformLanguage.id,
            },
            user: {
              email: this.information.email,
              password:
                this.information.password.length > 0
                  ? this.information.password
                  : null,
            },
            consultations: services,
            translations: [
              {
                language_id: 1,
                position: this.information.position.english,
                short_biography: this.information.shortBiography.english,
                biography: this.information.fullBiography.english,
              },
              {
                language_id: 2,
                position: this.information.position.french,
                short_biography: this.information.shortBiography.french,
                biography: this.information.fullBiography.french,
              },
            ],
          })
          .then(() => {
            this.toast.success(this.$t("successfulSaveData"));
            this.$store.commit("SET_LOADED_STATE", false);
            this.haveChanges = true;
            if (this.goToExperts) this.$router.push("/experts");
            this.goToExperts = true;
          })
          .catch((err) => {
            if (err.response.data.status === 400) {
              this.$store.commit("SET_LOADED_STATE", false);
              this.toast.error(err.response.data.message);
            } else {
              this.$store.commit("SET_LOADED_STATE", false);
              this.toast.error(this.$t("NetworkError"));
            }
          });
      }
    },
  },
  computed: {
    popupsInformation() {
      return this.$store.getters.STATE_POPUP;
    },
    haveChangesComputed() {
      let newValue = JSON.parse(JSON.stringify(this.information));
      let backupsValue = JSON.parse(JSON.stringify(this.informationBackups));
      newValue.fullBiography.english = newValue.fullBiography.english.replace(
        /(\<(\/?[^>]+)>)/g,
        ""
      );
      newValue.fullBiography.french = newValue.fullBiography.french.replace(
        /(\<(\/?[^>]+)>)/g,
        ""
      );
      newValue.shortBiography.english = newValue.shortBiography.english.replace(
        /(\<(\/?[^>]+)>)/g,
        ""
      );
      newValue.shortBiography.french = newValue.shortBiography.french.replace(
        /(\<(\/?[^>]+)>)/g,
        ""
      );

      if (backupsValue.length > 0) {
        backupsValue.fullBiography.english =
          backupsValue.fullBiography.english.replace(/(\<(\/?[^>]+)>)/g, "");
        backupsValue.fullBiography.french =
          backupsValue.fullBiography.french.replace(/(\<(\/?[^>]+)>)/g, "");
        backupsValue.shortBiography.english =
          backupsValue.shortBiography.english.replace(/(\<(\/?[^>]+)>)/g, "");
        backupsValue.shortBiography.french =
          backupsValue.shortBiography.french.replace(/(\<(\/?[^>]+)>)/g, "");
      }
      this.haveChanges =
        JSON.stringify(backupsValue) === JSON.stringify(newValue);
      return JSON.stringify(backupsValue) === JSON.stringify(newValue);
    },
    ...mapGetters({
      imageIsLoad: "LOADED_STATE",
    }),
  },
  watch: {
    popupsInformation: {
      deep: true,
      handler(newCount) {
        if (newCount.save) {
          this.editExpert();
          this.haveChanges = true;
          this.$router.push(newCount.to);
        }
        if (newCount.leave) {
          this.haveChanges = true;
          this.$router.push(newCount.to);
        }
      },
    },
  },
};
</script>

<style></style>
